import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ContentLayout from '../../../components/ContentLayout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import CommunityNavigation from '../../../components/community/CommunityNavigation';
import SponsorshipNavigation from '../../../components/community/SponsorshipNavigation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <ContentLayout title="Sponsorship" subTitle="Scholarships" nav={<CommunityNavigation />} subNav={<SponsorshipNavigation />}>
    {children}
    <hr />
    <p className="has-text-centered">
      <OutboundLink className="button is-primary" href="http://bit.ly/SDG-supporter-form">
        Support SDG
      </OutboundLink>
    </p>
  </ContentLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Scholarships`}</h3>
    <p>{`In today’s development industry, diversity is the greatest issues faced by employers to date. Currently, the percentage of women coders in the field is less than 20% while African Americans and Hispanic or Latino populations represent less than 3% of the overall coding community.`}</p>
    <p>{`For these reasons and more, we’ve created the Diversity Scholarship. Scholarships are offered on a need-based system, covering from $1,900 to $14,900 of tuition for recipients.`}</p>
    <p>{`Women, U.S. military veterans, and populations underrepresented in technology are eligible to apply. Scholarships are awarded based on financial need and the applicant's ability to meet the requirements of both the course and scholarship application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      